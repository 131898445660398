<template>
  <div class="card">
    <div class="rule-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
          {{ type ? $t(`form.bynder.rule.type.${type}.title`) : '' }}
        </h5>
        <p class="card-text">
          {{ $t(`form.bynder.rule.priority`) + ': ' + priority }}
        </p>
        <p class="card-text">
          {{ $t('form.bynder.rule.conditions.count', {n: conditions.length}) }}
        </p>
        <p v-if="config.fromField" class="card-text">
          {{ $t(`form.bynder.rule.field.fromField`) + ': ' + config.fromField }}
        </p>
        <p v-if="config.value" class="card-text">
          {{ $t(`form.bynder.rule.field.value`) + ': ' + config.value }}
        </p>
        <p v-if="config.regex" class="card-text">
          {{ $t(`form.bynder.rule.field.regex`) + ': ' + config.regex }}
        </p>
        <p v-if="config.toField" class="card-text">
          {{ $t(`form.bynder.rule.field.toField`) + ': ' + config.toField }}
        </p>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('form.bynder.rule.card.label') }}
            </h5>
          </div>
          <div class="modal-body">
            <SelectField
                class="mb-3"
                :id="this.id + 'type'"
                :label="$t('form.metadata_conf.type.title')"
                v-model="type"
                @change="cleanMetadataConf(type)"
                :options="types"
                :allowClear="false"
            />
            <NumberField
                id="priority"
                :label="$t('form.bynder.rule.priority')"
                v-model="priority"
            />
            <h5 class="w-75">{{ $t('form.bynder.rule.card.conditions.label') }}</h5>
            <BynderFilterFields
                id="conditions"
                :label="$t('form.bynder.rule.card.conditions.description')"
                :errors="getErrorMessage('conditions')"
                v-model="conditions"
            />
            <h5 class="w-75">{{ $t('form.bynder.rule.card.config.title') }}</h5>
            <TextField
                v-if="type ==='regex_extract'"
                id="from_field"
                :label="$t('form.bynder.rule.field.fromField')"
                v-model="config.fromField"
            />
            <TextField
                v-if="type ==='regex_extract'"
                id="to_field"
                :label="$t('form.bynder.rule.field.regex')"
                :tooltip="$t('form.bynder.rule.field.regex.tooltip')"
                v-model="config.regex"
            />
            <TextField
                v-if="type ==='regex_extract'"
                id="rege_pattern"
                :label="$t('form.bynder.rule.field.regexPattern')"
                :tooltip="$t('form.bynder.rule.field.regexPattern.tooltip')"
                v-model="config.regexPattern"
            />
            <TextField
                v-if="type ==='set_static_value'"
                id="value"
                :label="$t('form.bynder.rule.field.value')"
                v-model="config.value"
            />
            <TextField
                id="to_field"
                :label="$t('form.bynder.rule.field.toField')"
                v-model="config.toField"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeRule" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveRule" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";
import SelectField from "@/components/Form/SelectField.vue";
import TextField from "@/components/Form/TextField.vue";
import NumberField from "@/components/Form/NumberField.vue";

export default {
  name: "BynderRuleField",
  components: {
    NumberField,
    TextField,
    SelectField,
    BynderFilterFields,
  },
  props: {
    id: String,
    rule: Object,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      type: this.rule.type ?? "set_static_value",
      priority: Number(this.rule.priority) ?? 0,
      conditions: this.rule.conditions ?? [],
      errorMessage: this.error,
      config: this.getConfigurationFromRule(this.rule),
      types: [
        {id: 'set_static_value', text: 'form.bynder.rule.type.set_static_value.title'},
        {id: 'regex_extract', text: 'form.bynder.rule.type.regex_extract.title'},
      ],
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      if (this.errors && typeof this.errors === 'string') {
        return this.errors;
      }

      return null;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    getConfigurationFromRule (rule) {
      if (!rule.config) {
        return {};
      }

      if (rule.type === 'set_static_value') {
        return {
          toField: rule.config.toField ?? '',
          value: rule.config.value ?? '',
        }
      }

      if (rule.type === 'regex_extract') {
        return {
          fromField: rule.config.fromField ?? '',
          toField: rule.config.toField ?? '',
          regex: rule.config.regex ?? '//',
          regexPattern: rule.config.regexPattern ?? '',
        }
      }

      return {};
    },
    cleanMetadataConf (selectedType) {
      if (selectedType === 'set_static_value') {
        this.fromField    = null;
        this.regex        = null;
        this.regexPattern = null;
        this.value        = '';
      }

      if (selectedType === 'regex_extract') {
        this.value        = null;
        this.fromField    = '';
        this.regex        = '//'
        this.regexPattern = '';
      }
    },
    saveRule (e) {
      e.preventDefault();

      this.formErrors = {};
      this.modal.hide();

      let saveData = {
        priority: this.priority,
        type: this.type,
        conditions: this.conditions,
        config: {
          fromField: this.config.fromField,
          toField: this.config.toField,
          value: this.config.value,
          regex: this.config.regex,
          regexPattern: this.config.regexPattern,
        },
      }

      this.$emit('rule-save', saveData);
    },
    removeRule (e) {
      if (confirm(this.$t('form.bynder.rule.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('rule-remove');
      }

      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.type === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.rule-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>
